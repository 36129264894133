import axios from 'axios.js'
import { showNotification } from './GlobalActions'

export const SET_LOADING = 'EVENTADMIN_SET_LOADING'
export const GET_EVENTADMIN = 'EVENTADMIN_GET_EVENTADMIN'
export const CREATE_EVENTADMIN = 'EVENTADMIN_CREATE_EVENTADMIN'
export const DELETE_EVENTADMIN = 'EVENTADMIN_DELETE_EVENTADMIN'
export const DETAIL_EVENTADMIN = 'EVENTADMIN_DETAIL_EVENTADMIN'
export const UPDATE_EVENTADMIN = 'EVENTADMIN_UPDATE_EVENTADMIN'


export const getEventAdmin = (customParams) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'list',
            value: true,
        },
    })

    const params = {
        ...customParams,
        is_event_admin: true,
    }
    
    return axios.get('/api/user/', {
        params,
    }).then((res) => {
        dispatch({
            type: GET_EVENTADMIN,
            payload: res.data,
        })
    })
}

export const createEventAdmin = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'create',
            value: true,
        },
    })

    const formData = new FormData();
    Object.keys(attributes).forEach((name) => {
        formData.append(name, attributes[name]);
    })

    return axios.post('/api/user/', formData).then((res) => {
        dispatch({
            type: CREATE_EVENTADMIN,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Admin Event Berhasil ditambahkan',
            severity: 'success',
        }))
        dispatch({
            type: SET_LOADING,
            payload: {
                stateName: 'create',
                value: false,
            },
        })

        return {
            is_error: false,
            response: res
        };
    }).catch(e => {
        console.log('ERRROR =>>>>>>>>>>>>>>')
        console.log("🚀 ~ file: EventAdminActions.js ~ line 68 ~ returnaxios.post ~ e", e)
        dispatch({
            type: SET_LOADING,
            payload: {
                stateName: 'create',
                value: false,
            },
        })

        var msg = Object.keys(e).map(d => e[d].join(', ')).join(', ')
        dispatch(showNotification({
            open: true,
            message: msg,
            severity: 'error',
        }))
        
        return {
            is_error: true,
            response: e,
        };
    })
    
    // return  Promise.resolve((resolve, reject) => {
    //     axios.post('/api/user/', formData).then((res) => {
    //         dispatch({
    //             type: CREATE_EVENTADMIN,
    //             payload: res.data,
    //         })
    //         dispatch(showNotification({
    //             open: true,
    //             message: 'Admin Event Berhasil ditambahkan',
    //             severity: 'success',
    //         }))
    //         dispatch({
    //             type: SET_LOADING,
    //             payload: {
    //                 stateName: 'create',
    //                 value: false,
    //             },
    //         })

    //         resolve(res);
    //     }).catch(e => {
    //         console.log('ERRROR =>>>>>>>>>>>>>>')
    //         console.log("🚀 ~ file: EventAdminActions.js ~ line 68 ~ returnaxios.post ~ e", e)
    //         dispatch({
    //             type: SET_LOADING,
    //             payload: {
    //                 stateName: 'create',
    //                 value: false,
    //             },
    //         })
            
    //         reject(e);
    //     })
    // }) 
}

export const deleteEventAdmin = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'delete',
            value: true,
        },
    })
    return axios.delete(`/api/user/${id}/`).then((res) => {
        dispatch({
            type: DELETE_EVENTADMIN,
            payload: res.data,
        })

        dispatch(showNotification({
            open: true,
            message: 'Admin Event berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailEventAdmin = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detail',
            value: true,
        },
    })
    return axios.get(`/api/user/${id}/`).then((res) => {
        dispatch({
            type: DETAIL_EVENTADMIN,
            payload: res.data,
        })
    })
}

export const updateEventAdmin = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'update',
            value: true,
        },
    })
    const formData = new FormData();
    Object.keys(attributes).forEach((name) => {
        formData.append(name, attributes[name]);
    })
    return axios.patch(`/api/user/${id}/`, formData).then((res) => {
        dispatch({
            type: UPDATE_EVENTADMIN,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Admin Event Berhasil Diperbarui',
            severity: 'success',
        }))
    })
}

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const BrandIndex = Loadable(lazy(() => import("./BrandIndex")));
const BrandForm = Loadable(lazy(() => import("./BrandForm")));

const brandRoutes = [
    {
        path: '/master/brand',
        element: <BrandIndex />,
    },
    {
        path: '/master/brand/add',
        element: <BrandForm formType="create" />
    },
    {
        path: '/master/brand/edit/:id',
        element: <BrandForm formType="edit" />
    }
]

export default brandRoutes

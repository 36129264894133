import {
    SET_LOADING,
    GET_EVENTADMIN,
    CREATE_EVENTADMIN,
    DELETE_EVENTADMIN,
    DETAIL_EVENTADMIN,
    UPDATE_EVENTADMIN,
} from '../actions/EventAdminActions'

const initialState = {
    list: {
        loading: false,
        data: {},
    },
    detail: {
        loading: false,
        data: null
    },
    create: {
        loading: false,
        data: null
    },
    update: {
        loading: false,
        data: null
    },
    delete: {
        loading: false,
        data: null,
    }
}

const EventAdminReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            const obj = {
                ...state,
            }
            obj[action.payload.stateName].loading = action.payload.value;
            return obj
        }
        case GET_EVENTADMIN: {
            return {
                ...state,
                list: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DETAIL_EVENTADMIN: {
            return {
                ...state,
                detail: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case CREATE_EVENTADMIN: {
            return {
                ...state,
                create: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case UPDATE_EVENTADMIN: {
            return {
                ...state,
                update: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        case DELETE_EVENTADMIN: {
            return {
                ...state,
                delete: {
                    data: action.payload,
                    loading: false
                },
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default EventAdminReducer

import axios from 'axios.js'
import { showNotification } from './GlobalActions'

export const SET_LOADING = 'BRAND_SET_LOADING'
export const GET_BRAND = 'BRAND_GET_BRAND'
export const CREATE_BRAND = 'BRAND_CREATE_BRAND'
export const DELETE_BRAND = 'BRAND_DELETE_BRAND'
export const DETAIL_BRAND = 'BRAND_DETAIL_BRAND'
export const UPDATE_BRAND = 'BRAND_UPDATE_BRAND'


export const getBrand = (params) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'list',
            value: true,
        },
    })
    return axios.get('/api/brand/', {
        params,
    }).then((res) => {
        dispatch({
            type: GET_BRAND,
            payload: res.data,
        })
    })
}

export const createBrand = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'create',
            value: true,
        },
    })

    const formData = new FormData();
    Object.keys(attributes).forEach((name) => {
        formData.append(name, attributes[name]);
    })
    
    return axios.post('/api/brand/', formData).then((res) => {
        dispatch({
            type: CREATE_BRAND,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Brand Berhasil ditambahkan',
            severity: 'success',
        }))
    })
}

export const deleteBrand = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'delete',
            value: true,
        },
    })
    return axios.delete(`/api/brand/${id}/`).then((res) => {
        dispatch({
            type: DELETE_BRAND,
            payload: res.data,
        })

        dispatch(showNotification({
            open: true,
            message: 'Brand berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailBrand = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detail',
            value: true,
        },
    })
    return axios.get(`/api/brand/${id}`).then((res) => {
        dispatch({
            type: DETAIL_BRAND,
            payload: res.data,
        })
    })
}

export const updateBrand = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'update',
            value: true,
        },
    })
    const formData = new FormData();
    Object.keys(attributes).forEach((name) => {
        formData.append(name, attributes[name]);
    })
    
    return axios.patch(`/api/brand/${id}/`, formData).then((res) => {
        dispatch({
            type: UPDATE_BRAND,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Brand Berhasil Diperbarui',
            severity: 'success',
        }))
    })
}

import axios from 'axios.js'
import { showNotification } from './GlobalActions'

export const SET_LOADING = 'EVENT_SET_LOADING'
export const GET_EVENT = 'EVENT_GET_EVENT'
export const CREATE_EVENT = 'EVENT_CREATE_EVENT'
export const DELETE_EVENT = 'EVENT_DELETE_EVENT'
export const DETAIL_EVENT = 'EVENT_DETAIL_EVENT'
export const UPDATE_EVENT = 'EVENT_UPDATE_EVENT'


export const getEvent = (params) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'list',
            value: true,
        },
    })
    return axios.get('/api/event/', {
        params,
    }).then((res) => {
        dispatch({
            type: GET_EVENT,
            payload: res.data,
        })
    })
}

export const createEvent = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'create',
            value: true,
        },
    })
    return axios.post('/api/event/', attributes).then((res) => {
        dispatch({
            type: CREATE_EVENT,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Event Berhasil ditambahkan',
            severity: 'success',
        }))
    })
}

export const deleteEvent = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'delete',
            value: true,
        },
    })
    return axios.delete(`/api/event/${id}/`).then((res) => {
        dispatch({
            type: DELETE_EVENT,
            payload: res.data,
        })

        dispatch(showNotification({
            open: true,
            message: 'Event berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailEvent = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detail',
            value: true,
        },
    })
    return axios.get(`/api/event/${id}/`).then((res) => {
        dispatch({
            type: DETAIL_EVENT,
            payload: res.data,
        })
    })
}

export const updateEvent = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'update',
            value: true,
        },
    })
    return axios.patch(`/api/event/${id}/`, attributes).then((res) => {
        dispatch({
            type: UPDATE_EVENT,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'Event Berhasil Diperbarui',
            severity: 'success',
        }))
    })
}

export const navigations = [
    // {
    //     name: 'Dashboard',
    //     path: '/dashboard/default',
    //     icon: 'dashboard',
    //     roles: ['admin', 'sales'],
    // },
    {
        label: 'Master Data',
        type: 'label',
    },
    {
        name: 'Brand',
        icon: 'filter_frames',
        path: '/master/brand',
    },
    {
        name: 'Event',
        icon: 'event_note',
        path: '/master/event',
    },
    {
        name: 'Event Admin',
        icon: 'person_pin',
        path: '/master/eventAdmin',
    },
    {
        label: 'Pixelart Jobs',
        type: 'label',
    },
    {
        name: 'Results',
        icon: 'receipt',
        path: '/pixelart/result',
    },
    // {
    //     name: 'Carousel',
    //     icon: 'account_tree',
    //     path: '/branch/default',
    //     roles: ['admin'],
    // },
    // {
    //     name: 'Manage Branch',
    //     icon: 'account_tree',
    //     path: (user) => `/branch/edit/${user.branch_id}`,
    //     roles: ['user'],
    // },
    // {
    //     label: 'User',
    //     type: 'label',
    //     roles: ['admin'],
    // },
    // {
    //     name: 'User',
    //     icon: 'group',
    //     path: '/user/default',
    //     roles: ['admin'],
    // },
    // {
    //     name: 'User Admin',
    //     icon: 'launch',
    //     type: 'extLink',
    //     path: 'http://demos.ui-lib.com/matx-react-doc/',
    // },
]

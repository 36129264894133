import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const EventIndex = Loadable(lazy(() => import("./EventIndex")));
const EventForm = Loadable(lazy(() => import("./EventForm")));

const eventRoutes = [
    {
        path: '/master/event',
        element: <EventIndex />,
    },
    {
        path: '/master/event/add',
        element: <EventForm formType="create" />
    },
    {
        path: '/master/event/edit/:id',
        element: <EventForm formType="edit" />
    }
]

export default eventRoutes

import axios from 'axios.js'
import { showNotification } from './GlobalActions'

export const SET_LOADING = 'user/SET_LOADING'
export const GET_PIXELARTRESULT = 'user/GET_PIXELARTRESULT'
export const CREATE_PIXELARTRESULT = 'user/CREATE_PIXELARTRESULT'
export const DELETE_PIXELARTRESULT = 'user/DELETE_PIXELARTRESULT'
export const DETAIL_PIXELARTRESULT = 'user/DETAIL_PIXELARTRESULT'
export const UPDATE_PIXELARTRESULT = 'user/UPDATE_PIXELARTRESULT'


export const getPixelArtResult = (params = {}) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'list',
            value: true,
        },
    })
    return axios.get('/api/pixel-art/', {
        params
    }).then((res) => {
        console.log("🚀 ~ file: PixelArtResultActions.js ~ line 23 ~ getPixelArtResult ~ res", res)
        dispatch({
            type: GET_PIXELARTRESULT,
            payload: res.data,
        })
    })
}

export const createPixelArtResult = (attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'create',
            value: true,
        },
    })
    return axios.post('/transaction', attributes).then((res) => {
        dispatch({
            type: CREATE_PIXELARTRESULT,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'PixelArtResult Berhasil ditambahkan',
            severity: 'success',
        }))
    })
}

export const deletePixelArtResult = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'delete',
            value: true,
        },
    })
    return axios.delete(`/transaction/${id}`).then((res) => {
        dispatch({
            type: DELETE_PIXELARTRESULT,
            payload: res.data,
        })

        dispatch(showNotification({
            open: true,
            message: 'PixelArtResult berhasil dihapus',
            severity: 'success',
        }))
    })
}

export const detailPixelArtResult = (id) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'detail',
            value: true,
        },
    })
    return axios.get(`/api/pixel-art/${id}`).then((res) => {
        dispatch({
            type: DETAIL_PIXELARTRESULT,
            payload: res.data,
        })
    })
}

export const updatePixelArtResult = (id, attributes) => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: {
            stateName: 'update',
            value: true,
        },
    })
    return axios.patch(`/transaction/${id}`, attributes).then((res) => {
        dispatch({
            type: UPDATE_PIXELARTRESULT,
            payload: res.data,
        })
        dispatch(showNotification({
            open: true,
            message: 'PixelArtResult Berhasil Diperbarui',
            severity: 'success',
        }))
    })
}
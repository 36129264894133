import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import BrandReducer from './BrandReducer'
import WilayahReducer from './WilayahReducer'
import GlobalReducer from './GlobalReducer'
import UserReducer from './UserReducer'
import TransactionReducer from './TransactionReducer'

import PixelArtResultReducer from './PixelArtResultReducer'
import EventReducer from './EventReducer'
import EventAdminReducer from './EventAdminReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    brand: BrandReducer,
    wilayah: WilayahReducer,
    global: GlobalReducer,
    user: UserReducer,
    transaction: TransactionReducer,
    pixelArtResult: PixelArtResultReducer,
    event: EventReducer,
    eventAdmin: EventAdminReducer,
})

export default RootReducer

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const EventAdminIndex = Loadable(lazy(() => import("./EventAdminIndex")));
const EventAdminForm = Loadable(lazy(() => import("./EventAdminForm")));

const eventAdminRoutes = [
    {
        path: '/master/eventAdmin',
        element: <EventAdminIndex />,
    },
    {
        path: '/master/eventAdmin/add',
        element: <EventAdminForm formType="create" />
    },
    {
        path: '/master/eventAdmin/edit/:id',
        element: <EventAdminForm formType="edit" />
    }
]

export default eventAdminRoutes

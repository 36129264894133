import axios from 'axios'

// export const baseURL = 'http://localhost:3333';
export const baseURL = 'https://nftics.demo-kota.com';

const axiosInstance = axios.create({
    baseURL,
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.response) {
            console.log("🚀 ~ file: axios.js ~ line 14 ~ error.response", error.response)
            // if(error.response.status === 400 && error.response.data.message === 'Invalid credentials' && !window.location.href.indexOf('/session')) {
            //     window.localStorage.clear();
            //     window.location.reload();
            // }

            if(error.response.status === 401 && window.location.href.indexOf('/session') < 0) {
                window.localStorage.clear();
                window.location.reload();
            }
        }

        return Promise.reject(
            (error.response && error.response.data) || 'Something went wrong!'
        )
    }
        
)

export default axiosInstance


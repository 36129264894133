import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const PixelArtResultIndex = Loadable(lazy(() => import("./PixelArtResultIndex")));
const PixelArtResultDetail = Loadable(lazy(() => import("./PixelArtResultDetail")));

const pixelArtResultRoutes = [
    {
        path: '/pixelart/result',
        element: <PixelArtResultIndex />,
    },
    {
        path: '/pixelart/result/detail/:id',
        element: <PixelArtResultDetail />,
    }
]

export default pixelArtResultRoutes

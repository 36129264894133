import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
// import chartsRoute from 'app/views/charts/ChartsRoute'
// import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import brandRoutes from 'app/views/brand/BrandRoutes'
import pixelArtRoutes from 'app/views/pixelartresult/PixelArtResultRoutes'
import eventRoutes from 'app/views/event/EventRoutes'
import eventAdminRoutes from 'app/views/eventAdmin/EventAdminRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [...brandRoutes, ...pixelArtRoutes, ...eventRoutes, ...eventAdminRoutes],

        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="master/brand" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
